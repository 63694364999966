<template>
  <b-container fluid>
    <b-row class="py-2">
      <!-- Buttons-->
      <b-button variant="info" @click="rowClickHandler(null)"><b-icon icon="plus" aria-hidden="true" ></b-icon> Добавить новую группу</b-button>
      <b-modal id="editParamCodeMappingModal" hide-footer title="Группа соответствия аналитов">
        <b-form class="px-2" @submit.prevent="saveParamCodeMapping">
          <b-row class="py-2">
            <b-form-input class="py-1" v-model="paramCodeMappingName"></b-form-input>
          </b-row>
          <b-row class="">
            <b-button variant="info" type="submit" >Сохранить</b-button>
          </b-row>
        </b-form>
      </b-modal>
    </b-row>
    <b-row>
      <b-tabs class="w-100" pills card vertical>
        <b-tab v-for="item in paramCodeMappingList" :key="item.value" :title="item.text">

          <b-container fluid>
            <b-row class="py-2">
              <b-button variant="outline-info" @click="rowClickHandler(item)">
                <b-icon variant="info" icon="pencil" size="lg"></b-icon> Редактировать группу
              </b-button>
              &nbsp;
              <b-button variant="outline-info" @click="deleteParamCodeMapping(item)">
                <b-icon variant="info" icon="x-circle" size="lg"></b-icon> Удалить группу
              </b-button>
            </b-row>
            <b-row class="py-2">
              <ParamCodeMappingParams v-bind:param-code-mapping-id="item.value"></ParamCodeMappingParams>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants";
import ParamCodeMappingParams from "@/components/admin/paramcodemapping/ParamCodeMappingParams";

export default {
  name: "ParamCodeMappings",
  components: {ParamCodeMappingParams},
  data() {
    return {
      paramCodeMappingList: [],
      paramCodeMappingName: "",
      paramCodeMappingId: null,
      loading:false
    }
  },
  methods: {
    fetchData() {
      fetch(host + '/rest/paramcodemappings', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.paramCodeMappingList = json.options
          }).catch(error => {
        console.log(error)
      })
    },
    saveParamCodeMapping() {
      let method = 'POST'
      let rq = {paramCodeMappingName: this.paramCodeMappingName}
      if (this.paramCodeMappingId != null) {
        method = 'PUT'
        rq = {paramCodeMappingName: this.paramCodeMappingName, paramCodeMappingId: this.paramCodeMappingId}
      }
      this.loading = true
      fetch(host + '/rest/paramcodemappings', {
        credentials: "include",
        method: method,
        body: JSON.stringify(rq),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.$messageToast('Группа ' + this.paramCodeMappingName)
              this.$bvModal.hide('editParamCodeMappingModal')
              this.fetchData()
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }
            this.loading = false
          }).catch(error => {
            this.$errorToast('Возникла ошибка. Попробуйте повторить операцию.')
            this.loading = false
          })
    },
    rowClickHandler(item) {
      if (item != null) {
        this.paramCodeMappingName = item.text
        this.paramCodeMappingId = item.value
      } else {
        this.paramCodeMappingName = ""
        this.paramCodeMappingId = null
      }
      this.$bvModal.show('editParamCodeMappingModal')
    },
    deleteParamCodeMapping(item) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить группу кодов?')) {

        fetch(host + '/rest/paramcodemappings/' + item.value , {
          method: 'DELETE',
          credentials: 'include'
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.fetchData()

              } else {

                this.$errorToast(json.message);
              }

            }).catch(error => {
          this.$errorToast(error)
          console.log(JSON.stringify(error))

        })
      }

    },
  },
  mounted() {
    this.fetchData()
  }

}
</script>

<style scoped>

</style>