<template>
  <b-container fluid>
    <b-row class="py-2">
      <b-button variant="info" @click="showEditParamCodeMappingParamModal(null)">Добавить код внешней системы</b-button>
      <b-modal :id="'editParamCodeMappingParamModal' + paramCodeMappingId" hide-footer title="Элемент соответствия аналитов">
        <b-form class="px-2" @submit.prevent="saveParamCodeMappingParam">
          <b-row class="py-2">
            <b-col cols="12">Код аналита внешней системы</b-col>
            <b-col cols="12"><input type="text" class="w-100 py-1 analit-input log_in login-input" v-model="paramCodeAlphalab" required :disabled="currentItem != null" style="height: 42px!important; border-style: solid!important; border-radius: 15px!important;"></input></b-col>
          </b-row>
          <b-row class="py-2">
            <b-col cols="12">Аналит</b-col>
            <b-col cols="12">
              <treeselect
                          name="param" id="param" placeholder=""
                          clearable searchable required
                          open-on-click close-on-select disableFuzzyMatching
                          :noResultsText="'Не найдено...'"
                          :noOptionsText="'Список опций пуст'"
                          :options="selectOptions | searchQueryFilter(selected, childrenSearchQuery)"
                          :max-height="200"
                          :normalizer="normalizeTreeselect"
                          v-model="selected.paramId"  @search-change="childrenSearchChange">
<!--                <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName + 'customOptionLabel'">-->
<!--                  <span :title="getNodeLabel(node.label)">{{node.label | nodeLabelFilter}}</span>-->
<!--                </label>-->
<!--                <div slot="value-label" slot-scope="{ node}" class="vue-treeselect__multi-value ">-->

<!--                  <span class="" :title="getNodeLabel(node.label)">{{node.label | nodeLabelFilter}}</span>-->

<!--                </div>-->
              </treeselect>
            </b-col>
          </b-row>
          <b-row class="">
            <b-button variant="info" type="submit" >Сохранить</b-button>
          </b-row>
        </b-form>
      </b-modal>
    </b-row>
    <b-row class="py-2">
      <b-col cols="2">Загрузка файла</b-col>
      <b-col cols="8">
        <b-form-file
            v-model="fileToRecognize"
            :state="Boolean(fileToRecognize)"
            placeholder="Выберите файл на диске или перетащите..."
            drop-placeholder="Перетащите файлы..."
            browse-text="Обзор"
        ></b-form-file>
      </b-col>
      <b-col cols="2"><b-button variant="info" :disabled="!Boolean(fileToRecognize)" @click="sendFileToRecognize()">Загрузить файл<b-spinner v-if="loading" variant="light" small></b-spinner></b-button></b-col>
    </b-row>
    <b-row class="py-2">
      <b-table striped hover :items="items" :fields="fields">
        <template v-slot:cell(paramTitle)="data">
          {{ data.item.paramName }}, {{ data.item.biomaterialTypeName }}

        </template>
        <template v-slot:cell(buttons)="data">
          <h3 class="buttons">
            <nobr>
              <b-link @click="showEditParamCodeMappingParamModal(data.item)">
                <b-icon variant="info" icon="pencil" size="lg"></b-icon>
              </b-link>
              &nbsp;
              <b-link @click="deleteParamCodeMappingParam(data.item)">
                <b-icon variant="info" icon="x-circle" size="lg"></b-icon>
              </b-link>

            </nobr>
          </h3>
        </template>
      </b-table>
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants";
import Treeselect from "@riophae/vue-treeselect";

export default {
  //props: ['paramCodeMappingId'],
  props: {
    paramCodeMappingId: Number,
    //selectOptions: Array
  },
  name: "ParamCodeMappingParams",
  components: {Treeselect},
  data() {
    return {
      items:[],
      fields: [
        {
          key: 'paramCodeAlphalab',
          label: 'Код аналита внешней системы',
          sortable: true
        },
        {
          key: 'paramTitle',
          label: 'Аналит',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      error: null,
      fileToRecognize: null,
      loading: false,
      currentItem: null,
      paramCodeAlphalab: null,
      selected: {paramId:null},
      childrenSearchQuery: "",
      normalizeTreeselect(node) {
        return {
          id: node.value.paramId,
          label: node.value.paramName + (node.value.biomaterialTypeName ? ', ' + node.value.biomaterialTypeName : '')
        }
      },
      selectOptions: [],
    }
  },
  filters: {
    searchQueryFilter: function (options, diseases, searchQuery) {


      console.log('searchQueryFilter ' + searchQuery)
      let result = []

        result = options.filter((el, index, []) => {
          let r = diseases.paramId === el.value.paramId || ((searchQuery) && (el.value.searchString).toLowerCase().includes(searchQuery.toLowerCase()) )
          console.log('filter ' + r)
          return r
        })

      console.log('searchQueryFilter result size ' + result.length)

      return result
    },
    nodeLabelFilter(value) {
      if (value) {
        return value.substring(0, value.indexOf(';_;_;'))
      }
    }
  },
  methods: {
    deleteParamCodeMappingParam(item) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить выбранное соответствие?')) {

        fetch(host + '/rest/paramcodemappings/' + this.paramCodeMappingId + '/params/' + item.paramCodeAlphalab, {
          method: 'DELETE',
          credentials: 'include'
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.fetchData()
              } else {
                this.$errorToast(json.message);
              }
            }).catch(error => {
              this.$errorToast(error)
              console.log(JSON.stringify(error))
            })
      }
    },
    childrenSearchChange(searchQuery, instanceId) {
      if (searchQuery && searchQuery.length >= 2)
        this.childrenSearchQuery = searchQuery
      else
        this.childrenSearchQuery = ''
      console.log('childrenSearchQuery ' + this.childrenSearchQuery)
    },
    getNodeLabel(value) {
      if (value) {
        return value.substring(0, value.indexOf(';_;_;'))
      }

    },
    fetchData() {
      fetch(host + '/rest/paramcodemappings/' + this.paramCodeMappingId + '/params', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.options
          }).catch(error => {
        console.log(error)
      })
    },
    showEditParamCodeMappingParamModal(item = null) {
      if (item != null) {
        this.currentItem = item
        this.selected.paramId = item.paramId
        this.paramCodeAlphalab = item.paramCodeAlphalab
      } else {
        this.currentItem = null
        this.selected.paramId = null
        this.paramCodeAlphalab = null
      }
      this.$bvModal.show('editParamCodeMappingParamModal' + this.paramCodeMappingId)
    },
    saveParamCodeMappingParam() {
      let method = 'POST'
      let rq = {paramCodeAlphalab: this.paramCodeAlphalab, paramId: this.selected.paramId}
      if (this.currentItem != null) {
        method = 'PUT'
      }

      fetch(host + '/rest/paramcodemappings/' + this.paramCodeMappingId + '/params', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(rq),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {

            if (json.resultCode != 0) {
              this.$errorToast(json.message)
            } else {
              this.$messageToast('Данные соответствия')
              this.$bvModal.hide('editParamCodeMappingParamModal' + this.paramCodeMappingId)
            }
            this.loading = false
            this.fetchData()
          }).catch(error => {
        this.$errorToast('Ошибка сохранения')
        this.loading = false
      })

    },

    sendFileToRecognize() {
      this.loading = true
      console.log((this.fileToRecognize))

      if (this.fileToRecognize) {

        var reader  = new FileReader();
        var curThis = this

        reader.onloadend = function () {

          console.log(reader.result)
          let rq = {blankId: curThis.selectedBlank, name: curThis.fileToRecognize.name, type:curThis.fileToRecognize.type, content: reader.result.substring(reader.result.indexOf('base64,') + 7 )}
          console.log(JSON.stringify(rq))

          fetch(host + '/rest/paramcodemappings/' + curThis.paramCodeMappingId + '/params/file', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(rq),
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          }).then(response => response.json())
              .then(json => {
                console.log('Response ' + JSON.stringify(json))
                if (json.message.length > 0) {
                  curThis.$errorToast(json.message)
                } else {
                  curThis.$messageToast('Данные файла группы соответствия')
                }
                curThis.loading = false
                curThis.fetchData()
              }).catch(error => {

            console.log(error)
            curThis.loading = false
          })

        }

        reader.readAsDataURL(this.fileToRecognize);

      }
    },

    prepareAnalitsFromResponse(params) {
      this.selectOptions = []
      //this.params = params
      let selectParent = false
      for (let p of params) {

        let option = {
          value: p,
          text: p.paramName
        }
        this.selectOptions.push(option)
        // if (this.currentAnalit && this.currentAnalit.parentParamId == p.paramId && this.currentAnalit.onlyOneSelect) {
        //   this.selected = p
        //   this.selected.onlyOneSelect = this.currentAnalit.onlyOneSelect
        //   selectParent = true
        //   this.onParamSelect()
        // } else if (this.currentAnalit && !selectParent && this.currentAnalit.paramId == p.paramId) {
        //   this.selected = p
        //   this.onParamSelect()
        // }
      }
      console.log('fetching end')
      // if (this.currentAnalit !== null) {
      //   console.log('Current analit is ' + JSON.stringify(this.currentAnalit))
      //   this.measurementUnitId = this.currentAnalit.measurementUnitId
      //
      // }
      //this.$forceUpdate()
    },


  },
  mounted() {
    this.fetchData()
    fetch(host + '/rest/params', {
      credentials: "include"
    })
        .then(response => response.json())
        .then(json => {
          this.prepareAnalitsFromResponse(json.params)
        })
  }
}
</script>

<style scoped>

</style>